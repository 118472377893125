import React, { useCallback, useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { withStyles, WithStyles, createStyles, CircularProgress, ButtonBase } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from 'store';
import { withAuth } from 'config/';
import Layout from 'components/UI/Layout';
import Input from 'components/UI/Input';
import ScreenTitle from 'components/Shared/ScreenTitle';
import EmptyList from 'components/Shared/EmptyList';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { ReactComponent as CloseIcon } from 'assets/close.svg';

import { KreiseProps } from './model';
import KreiseHeading from './components/KreiseHeading';
import KreiseItem from './components/KreiseItem';
import { useLanguage } from 'languages/languageContext';
import { getConfig } from 'config/config';
import { clearKreise, getKreise, getUserKreise, searchKreise, sendKreiseRequest } from 'store/reducers/kreise';
import { showResponse } from 'store/actions/response';
import DefaultModal from 'components/Shared/DefaultModal';
import Button from 'components/UI/Button';
import { debounce } from 'utilities';
import { IonIcon } from 'components/UI/IonIcon';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    width: '100%',
    padding: '10px 0',
    boxSizing: 'border-box',
  },
  title: {
    textAlign: 'center',
  },
  wrapper: {
    padding: '0 30px 0 30px',
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  inputWrapper: {
    width: '100%',
    padding: '30px 20px 0',
    position: 'relative',
    boxSizing: 'border-box',
  },
  input: {
    width: '100%',
    border: '1px solid',
    borderRadius: 50,
    padding: '15px 20px',
    boxSizing: 'border-box',
    '&::placeholder': {
      fontSize: 15,
      fontFamily: 'Roboto',
      color: '#353A54',
    },
  },
  iconWrapper: {
    position: 'absolute',
    right: 45,
    top: 43,
    zIndex: 10,
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, KreiseProps {}

const Kreise: React.FC<Props> = (props) => {
  const { circles, searchInput, screenTitles, btn } = useLanguage();
  const dispatch = useAppDispatch();
  const { kreiseIsLoading, kreiseNamesIsLoading, kreise, ownerKreise, kreiseCount, kreiseSubscribedCount } =
    useAppSelector((state) => state.kreise);
  const user = useAppSelector((state) => state.users.user);
  const [unsubPageNumber, setUnsubPageNumber] = useState(0);
  const [subPageNumber, setSubPageNumber] = useState(1);

  const [searchPageNumber, setSearchPageNumber] = useState(0);
  const [query, setQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const [kreiseRequestModal, setKreiseRequestModal] = useState({
    show: false,
    id: '',
    name: '',
  });

  const { classes, history } = props;

  const unsubscribedKreise = kreise;

  const hasMoreSubs = ownerKreise.length < kreiseSubscribedCount;
  const hasMoreUnsubs = kreise.length < kreiseCount;
  const hasMore = hasMoreSubs || hasMoreUnsubs;

  useEffect(() => {
    if (history.action === 'PUSH') {
      dispatch(clearKreise());
      getInitialData();
    }

    if (kreiseSubscribedCount === null) {
      getInitialData();
    }
  }, []);

  const getInitialData = () => {
    getMoreSubData();
    getMoreUnsubData();
  };

  const getMoreData = () => {
    if (kreiseIsLoading) return;
    if (kreiseNamesIsLoading) return;
    if (kreiseSubscribedCount === null) {
      getMoreSubData();
      getMoreUnsubData();
      return;
    }

    if (hasMoreSubs) {
      getMoreSubData();
      return;
    }
    if (hasMoreUnsubs) {
      getMoreUnsubData();
      return;
    }
  };

  const getMoreUnsubData = () => {
    if (!!query.trim().length) {
      dispatch(searchKreise({ query: query, skip: searchPageNumber, limit: 10 }));
      setSearchPageNumber((prev) => prev + 10);
      return;
    }
    dispatch(getKreise({ page: unsubPageNumber, limit: 10 }));
    setUnsubPageNumber((prev) => prev + 10);
  };

  const getMoreSubData = () => {
    if (query.length && subPageNumber * 10 > ownerKreise.length) return;
    let value = query.trim();
    dispatch(getUserKreise({ page: subPageNumber, limit: 10, query: value }));
    setSubPageNumber((prev) => prev + 1);
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.trim().length > 0) {
      setQuery(value);
      setSearchPageNumber(10);
      onChangeDebounce(value);
    }
    if (value.trim().length === 0) {
      clearSearch();
    }
  };

  const onChangeDebounce = useCallback(
    debounce((value: string) => {
      dispatch(searchKreise({ query: value, skip: 0, limit: 10 }));
      dispatch(getUserKreise({ query: value, page: 0, limit: 10, clean: true }));
    }, 500),
    [],
  );

  const toKreisePostsHandler = (kreise: any) => {
    if (kreise.privacyStatus === 'request' && kreise.request?.length) {
      dispatch(showResponse({ message: 'Anfrage bereits gesendet' }));
      return;
    }
    if (kreise.privacyStatus === 'request' && !kreise.is_subscribed) {
      setKreiseRequestModal({ show: true, id: kreise.id, name: kreise.name });
      return;
    }
    history.push(`/kreise-posts/${kreise.id}`, { kreise, kreiseId: kreise.id });
  };

  const focusHandler = (): void => {
    setIsFocused((prev) => !prev);
  };

  const clearSearch = () => {
    dispatch(clearKreise());
    setQuery('');
    setSearchPageNumber(0);
    setUnsubPageNumber(10);
    setSubPageNumber(2);
    dispatch(getKreise({ page: 0, limit: 10 }));
    dispatch(getUserKreise({ page: 1, limit: 10, clean: true }));
  };

  const subscribedKreises = (
    <div>
      <KreiseHeading title={circles.circlesTitle} />
      {ownerKreise?.length ? (
        ownerKreise.map((item, i) => (
          <KreiseItem kreise={item} toKreisePosts={toKreisePostsHandler} key={i} user={user} />
        ))
      ) : (
        <EmptyList />
      )}
    </div>
  );

  const unsubscribedKreises = (
    <div>
      <KreiseHeading title={circles.noCirclesTitle} />
      {unsubscribedKreise.length > 0 ? (
        unsubscribedKreise.map((item, i) => (
          <KreiseItem kreise={item} toKreisePosts={toKreisePostsHandler} key={i} user={user} />
        ))
      ) : (
        <EmptyList />
      )}
    </div>
  );

  const sendRequest = async () => {
    // await sendKreiseRequest(kreiseRequestModal.id);
    dispatch(sendKreiseRequest({ kreiseId: kreiseRequestModal.id }));
    setKreiseRequestModal({ id: '', show: false, name: '' });
    dispatch(showResponse({ message: `Die Mitgliedschaft in "${kreiseRequestModal.name}" wurde beantragt.` }));
  };

  return (
    <>
      <Layout>
        <ScreenTitle
          title={screenTitles.circlesTitle}
          rightBlock={
            <Link to={'/kreise/create'}>
              <IonIcon name="add-circle" size={40} />
            </Link>
          }
        />
        <div className={classes.container}>
          <div className={classes.inputWrapper}>
            <div className={classes.iconWrapper} style={{ top: query.length > 0 ? 66 : 43 }}>
              {query.length > 0 ? (
                <ButtonBase onClick={clearSearch}>
                  <CloseIcon />
                </ButtonBase>
              ) : (
                <SearchIcon />
              )}
            </div>
            <Input
              placeholder={searchInput.title}
              onChange={onChangeHandler}
              isFocused={isFocused}
              onBlur={focusHandler}
              onFocus={focusHandler}
              value={query}
            />
          </div>
          <InfiniteScroll
            threshold={50}
            initialLoad={false}
            pageStart={1}
            loadMore={getMoreData}
            hasMore={hasMore}
            loader={
              <div style={{ textAlign: 'center' }} key="9999">
                <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
              </div>
            }
            useWindow={true}
          >
            {subscribedKreises}
            {!!unsubscribedKreise.length && unsubscribedKreises}
          </InfiniteScroll>
        </div>
      </Layout>
      <DefaultModal
        showModal={kreiseRequestModal.show}
        modalTitle={'Mitgliedschaft'}
        onClose={() => setKreiseRequestModal({ id: '', show: false, name: '' })}
        message={'Mitgliedschaft beantragen?'}
      >
        <Button label={'Ja'} onClick={sendRequest} />
        <Button label={btn.cancelBtn} onClick={() => setKreiseRequestModal({ id: '', show: false, name: '' })} />
      </DefaultModal>
    </>
  );
};

export default withStyles(styles)(withAuth(Kreise));
