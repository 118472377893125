import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles, createStyles, ButtonBase } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import { withConfig, Theme } from 'config';
import EventToolbar from 'components/Shared/EventToolbar';
import { useLanguage } from 'languages/languageContext';
import moment from 'moment';
import { useCustomHistory } from 'hooks/useCustomHistory';
import { InfoOutlined } from '@material-ui/icons';

const styles = createStyles({
  container: {
    width: '100%',
    padding: '10px 20px',
    // borderRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    position: 'relative',
    boxSizing: 'border-box',
    marginBottom: 10,
    cursor: 'pointer',
  },
  editIconWrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 15,
    borderRadius: '50%',
    zIndex: 9999,
  },
  editIcon: {},
  titleWrapper: {
    maxWidth: '100%',
  },
  descItem: {
    marginBottom: 2,
    display: 'flex',
  },
  descProp: {
    fontFamily: 'Roboto',
    fontSize: 16,
    whiteSpace: 'pre',
  },
  descVal: {
    fontFamily: 'Roboto',
    fontSize: 16,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  title: {
    margin: '5px 0 10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '95%',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  event: any;
  date?: string;
}

const EventItem: React.FC<Props> = ({ classes, theme, event, history, date = '' }) => {
  const { eventLabels } = useLanguage();
  const { push, location } = useCustomHistory();
  const { organizers } = event;
  const [showZusagenModal, setShowZusagenModal] = useState<boolean>(false);

  const user = useSelector((state: any) => state.users.user);

  let eventCreator;

  if (user && user._id && event) {
    eventCreator = event?.sender?._id === user?._id;
  }

  const moreCreators = `${organizers.length > 1 ? ` (+${organizers.length - 1})` : ''}`;
  const fullNameCreator = `${organizers[0]?.name} ${organizers[0]?.lastname} ${moreCreators}`;

  let startTime;
  if (event && event.title) {
    startTime = moment(event.startDate).format('HH:mm');
  }

  const toEventDetails = () => {
    push('/calendar/' + event._id, { eventId: event._id });
  };

  const toEditEvent = () => {
    history.push('/edit-event/' + event._id, { event });
  };

  // used to hide several buttons
  const isCalendar = location.pathname === '/calendar';
  console.log(event.images);
  return event ? (
    <>
      {!!event?.images?.length && (
        <img
          src={event.images[0]?.thumb}
          style={{
            width: '100%',
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            height: 150,
            background: theme.BACKGROUND_PRIMARY,
            objectFit: 'cover',
            zIndex: 10,
          }}
          alt="eventPhoto"
        />
      )}
      <div className={classes.container} style={{ background: theme.BACKGROUND_PRIMARY }}>
        {eventCreator ? (
          <ButtonBase onClick={toEditEvent} className={classes.editIconWrapper}>
            <div className={classes.editIcon} style={{ color: theme.BUTTON_SECONDARY }}>
              <EditIcon color="inherit" />
            </div>
          </ButtonBase>
        ) : isCalendar ? (
          <ButtonBase onClick={toEventDetails} className={classes.editIconWrapper}>
            <div className={classes.editIcon} style={{ color: theme.BUTTON_SECONDARY }}>
              <InfoOutlined style={{ color: theme.ACTIVE_INPUT, width: 28, height: 28 }} />
            </div>
          </ButtonBase>
        ) : (
          <></>
        )}

        <div className={classes.titleWrapper} onClick={toEventDetails}>
          <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
            {event.title}
          </h3>
          <div className={classes.descItem}>
            <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
              {eventLabels.eventLabel}:{' '}
            </span>
            <span className={classes.descVal} style={{ color: theme.TEXT_PRIMARY }}>
              {event.organizerMsg}
            </span>
          </div>
          {date.length > 0 ? (
            <div className={classes.descItem}>
              <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
                {eventLabels.eventStartLabel}:{' '}
              </span>
              <span className={classes.descVal} style={{ color: theme.TEXT_PRIMARY }}>
                {date}
              </span>
            </div>
          ) : (
            <div className={classes.descItem}>
              <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
                {eventLabels.eventStartLabel}:{' '}
              </span>
              <span className={classes.descVal} style={{ color: theme.TEXT_PRIMARY }}>
                {startTime}
              </span>
            </div>
          )}
          <div className={classes.descItem}>
            <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
              {eventLabels.locationLabel}:{' '}
            </span>
            <span className={classes.descVal} style={{ color: theme.TEXT_PRIMARY }}>
              {event.location}
            </span>
          </div>
          <div className={classes.descItem}>
            <span className={classes.descProp} style={{ color: theme.TEXT_SECONDARY }}>
              {eventLabels.creatorLabel}:{' '}
            </span>
            <span className={classes.descVal} style={{ color: theme.TEXT_PRIMARY }}>
              {fullNameCreator}
            </span>
          </div>
        </div>
        <EventToolbar
          showMoreInfo={isCalendar}
          toDetailsHandler={toEventDetails}
          event={event}
          setShowZusagenModal={setShowZusagenModal}
          showZusagenModal={showZusagenModal}
        />
      </div>
    </>
  ) : null;
};

export default withStyles(styles)(withRouter(withConfig(EventItem)));
