import React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';
import Linkify from 'react-linkify';
import { withConfig, Theme } from '../../../config';
import { timeAgo } from '../../../utilities/';
import linkDecorator from '../../../components/UI/linkDecorator';
import { MessageAttachments } from './MessageAttachments';
import Content from 'components/UI/Content';
import { AttachmentsGallery } from 'components/Shared/AttachmentsGallery';
import { useAttachmentsUrls } from '../useAttachmentsUrls';

const styles = createStyles({
  container: {
    width: '100%',
    padding: '7px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
  },
  wrapper: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  messageWrapper: {
    maxWidth: '80%',
    padding: 8,
    borderRadius: '10px 10px 10px 0px',
    marginBottom: 10,
    whiteSpace: 'pre-wrap',
  },
  message: {
    fontFamily: 'Roboto',
    fontSize: 14,
    wordBreak: 'break-word',
    padding: '0 8px',
  },
  date: {
    fontFamily: 'Roboto',
    fontSize: 12,
  },
  imageWrapper: {
    border: '1px solid',
    marginRight: 10,
    width: 50,
    height: 50,
    borderRadius: 25,
    overflow: 'hidden',
  },
  '@media(max-width: 650px)': {
    container: {
      padding: '10px',
    },
    imageWrapper: {
      width: 40,
      height: 40,
      borderRadius: 20,
    },
    messageWrapper: {
      maxWidth: 250,
      padding: '10px 15px',
      borderRadius: '10px 10px 10px 0px',
      marginBottom: 10,
    },
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  message: any;
  sender: any;
}

const ReceivedMessage: React.FC<Props> = ({ classes, theme, message, sender }) => {
  const date = timeAgo(new Date(message.time));
  const fixed = useAttachmentsUrls(message.attachments);
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.messageWrapper} style={{ background: theme.BACKGROUND_SECONDARY }}>
          <AttachmentsGallery documents={fixed?.documents} videos={fixed?.videos} images={fixed?.images} />
          {!!message.msg.length && (
            <Linkify componentDecorator={linkDecorator}>
              <Content content={message.msg} className={classes.message} style={{ color: theme.TEXT_PRIMARY }} />
            </Linkify>
          )}
        </div>
        <span className={classes.date} style={{ color: theme.TEXT_SECONDARY }}>
          {date}
        </span>
      </div>
    </div>
  );
};

export default withStyles(styles)(withConfig(ReceivedMessage));
