import React, { useEffect, useState } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core';
import Linkify from 'react-linkify';
import { withConfig, Theme } from '../../../config';
import { timeAgo } from '../../../utilities/';
import linkDecorator from '../../../components/UI/linkDecorator';
import { attachmentsType, MessageAttachments } from './MessageAttachments';
import Content from 'components/UI/Content';
import { IonIcon } from 'components/UI/IonIcon';
import { AttachmentsGallery } from 'components/Shared/AttachmentsGallery';
import { getS3Link } from 'utilities/setS3Prefix';
import { useAttachmentsUrls } from '../useAttachmentsUrls';

const styles = createStyles({
  container: {
    width: '100%',
    padding: '7px 20px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  messageWrapper: {
    maxWidth: '70%',
    padding: '7px 15px',
    borderRadius: '10px 10px 0 10px',
    marginBottom: 10,
    whiteSpace: 'pre-wrap',
  },
  message: {
    fontFamily: 'Roboto',
    fontSize: 14,
    wordBreak: 'break-word',
    padding: '0 8px',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  message: any;
}

const SentMessage: React.FC<Props> = ({ classes, theme, message }) => {
  const date = timeAgo(new Date(message.time));
  const fixed = useAttachmentsUrls(message.attachments);

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.messageWrapper} style={{ background: theme.BUTTON_SECONDARY }}>
          <AttachmentsGallery documents={fixed?.documents} videos={fixed?.videos} images={fixed?.images} />
          {!!message.msg.length && (
            <Linkify componentDecorator={linkDecorator}>
              <Content
                content={message.msg}
                className={classes.message}
                style={{ color: theme.BACKGROUND_SECONDARY }}
              />
            </Linkify>
          )}
        </div>
        <span className={'text-xs flex flex-row'} style={{ color: theme.TEXT_SECONDARY }}>
          {date}
          {message.read === 1 && (
            <>
              {' '}
              - gelesen
              <IonIcon name="eye" color={'#688fb5'} style={{ marginLeft: 4 }} />
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default withStyles(styles)(withConfig(SentMessage));
